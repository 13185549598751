import React from 'react'
//
import DefaultHeader from '../../../components/DefaultHeader';
//
import AppIphone from '../../../assets/app_iphone.png';
//
import { constants } from '../../../constants';
//
import {
    FaMobileAlt,
    FaUserLock,
    FaChartPie,
    FaMoneyCheckAlt,
    FaCartPlus,
} from "react-icons/fa";
//
import styles from './banner-app.module.css';

export default function BannerApp() {
    return (
        <section id='app' className={styles['container']}>
            <DefaultHeader
                icon={<FaMobileAlt/>}
                subtitle={constants.texts.nomeEmpresa}
                title='Queremos lhe dar todo o controle na palma da mão'
                txt='Nossa geração aprendeu a pensar fora da caixa e não seria diferente com a maneira que você se relaciona com sua operadora.'
                dark
            />
            <div className={styles['container-app']}>
                <div className={styles['app-column']}>

                    <div className={styles['card']}>
                        <FaUserLock className={styles['icon-card']} />
                        <div className={styles['title-card']}>
                            Segurança
                        </div>
                        <div className={styles['txt-card']}>
                            Nosso maior foco é na segurança de
                            seus dados e do nosso aplicativo.
                        </div>
                    </div>

                    <div className={styles['card']}>
                        <FaChartPie className={styles['icon-card']} />
                        <div className={styles['title-card']}>
                            Gerencie seu plano
                        </div>
                        <div className={styles['txt-card']}>
                            Nosso maior foco é na segurança de
                            seus dados e do nosso aplicativo.
                        </div>
                    </div>

                </div>

                <div className={styles['app-column']}>
                    <a
                        href={constants.links.linkApp || '#app'}
                        target="_blank"
                        rel="noreferrer"
                        className={styles['app-iphone']}
                    >
                        <img
                            className={styles['app-iphone-img']}
                            alt='app_iphone'
                            src={AppIphone}
                        />
                    </a>
                </div>

                <div className={styles['app-column']}>

                    <div className={styles['card']}>
                        <FaMoneyCheckAlt className={styles['icon-card']} />
                        <div className={styles['title-card']}>
                            Faturas
                        </div>
                        <div className={styles['txt-card']}>
                            Com o aplicativo da {constants.texts.nomeEmpresa} você consegue
                            renovar o seu plano com um só clique.
                        </div>
                    </div>

                    <div className={styles['card']}>
                        <FaCartPlus className={styles['icon-card']} />
                        <div className={styles['title-card']}>
                            Acumulo de Gigas
                        </div>
                        <div className={styles['txt-card']}>
                            Gerencie os seus Gigas acumulados
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
